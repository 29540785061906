import React from 'react'
import PropTypes from 'prop-types'

import SkeletonLoading from '../SkeletonLoading/Loadable'
import BarChart1XComponent from '../BarChart1XComponent/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import ViolationCountStyled from './styledComponent'

class ViolationCount extends React.PureComponent {
  getGraph() {
    return (
      <CardWrapper cardTitle={this.props.title} titleId={'lbl-violation-title-graph'}>
        <div className="violation-count-wrapper">
          <div className="chart-container">
            <BarChart1XComponent
              data={this.props.data}
              listBars={this.props.listBars}
              xLabel={this.props.xLabel}
              yLabel={this.props.yLabel}
              xLabelID="lbl-time-violation-graph"
              yLabelID="lbl-count-violation-graph"
            />
          </div>
        </div>
      </CardWrapper>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getGraph()
    return content
  }

  render() {
    return <ViolationCountStyled style={this.props.style}>{this.getContent()}</ViolationCountStyled>
  }
}

ViolationCount.propTypes = {
  title: PropTypes.string,
  listBars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  xLabel: PropTypes.string.isRequired,
  yLabel: PropTypes.string.isRequired,
  style: PropTypes.object,
  isLoading: PropTypes.bool
}

export default ViolationCount
